
import { ref, defineComponent, onMounted } from "vue";
import useInit from '@/composables/v2/useInit';
import useLimits from '@/composables/v2/useLimits';
import SimpleStatisticBlock from "@/components/widgets/statistics/SimpleStatisticBlock.vue";

export default defineComponent({
  name: "management-account-limits",
  components: {
    SimpleStatisticBlock
  },
  setup() {
    const { toolbar } = useInit();
    const { initLimits, settings } = useLimits();

    initLimits();
    toolbar({init: false})

    return {
      settings,
    }
  }
});
