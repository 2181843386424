import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default function useLimits() {
  const store = useStore();
  const setting = ref();
  const settings = ref([]) as any

  interface Limit {
    name: string;
    label: string;
    description: string;
    max: number;
    used: number;
    currentPercentage: number;
    visualPercentage: number;
    colorStatus: string;
  }

  const initLimits = () => {
    store.dispatch(Actions.CONFIGURATION).then(async() => {
      setting.value = store.getters.getSetting('limits');

      for (const limit of setting.value) {
        const currentPercentage = await calculatePercentage(limit);

        const template: Limit = {
          name: limit.name,
          label: limit.label,
          description: limit.description,
          max: limit.max,
          used: limit.used,
          currentPercentage: currentPercentage,
          visualPercentage: calculateVisualPercentage(currentPercentage),
          colorStatus: generateColorStatus(currentPercentage)
        }
        settings.value.push(template)
      }
    })
  }

  const calculatePercentage = (settings) => {
    const used: number = settings.used;
    const max: number = settings.max;
    const percentage: number = Math.round((used / max * 100));
    return percentage;
  }

  const calculateVisualPercentage = (percentage) => {
    const visualPercentage: number = percentage > 100 ? 100 : percentage;
    return visualPercentage;
  }

  const generateColorStatus = (percentage) => {
    const colorStatus: string = percentage > 75 && percentage < 99 ? 'warning' : percentage > 99 ? 'danger' : 'success';
    return colorStatus;
  }

  return {
    initLimits,
    settings
  }
}