<template>
   <ManagementAccountLimits/>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import ManagementAccountLimits from '@/components/fixed/ManagementAccountLimits';

export default defineComponent({
  components: {
    ManagementAccountLimits,
  },
  setup() {
    const route = useRoute();

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId
    }
  }
});
</script>